/* eslint-disable prettier/prettier */
import ReactDOM from "react-dom/client";

import "@/app/css/main.css";
import "@/utils/i18next/i18n.ts";
import { RouterProvider, createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";
import { StrictMode } from "react";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

import "react-toastify/dist/ReactToastify.css";
const router = createRouter({ routeTree });

Sentry.init({
    dsn: "https://4dc9a99f71e856931857e9c811a108b6@o4508336376709120.ingest.us.sentry.io/4508415076335616",
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: false
        }),
    ],
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ToastContainer />
        <RouterProvider router={router} />
    </StrictMode>,
);
